import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
import dataV from '@jiaminghi/data-view'
import './util/rem'
import * as Echarts from 'echarts';
Vue.prototype.$Echarts = Echarts;
Vue.use(dataV)
import '@/icons'
// import Video from 'video.js'
// import 'video.js/dist/video-js.css'

// Vue.prototype.$video = Video

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import './permission'

Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
