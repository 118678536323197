import router from './router'
import store from './store'
router.beforeEach(async (to, from, next) => {
  if (to.query.tk) {
    store.commit('saveTk', to.query.tk)
  }
  var userAgent = navigator.userAgent.toLowerCase();
  store.commit('saveWind', (userAgent.indexOf('mobile') === -1 ? false : true))
  console.log(userAgent)
  next()
})