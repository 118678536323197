import axios from 'axios'
import store from "../store";
import { urlencode } from './common'
axios.defaults.withCredentials = true
export function requestApi(reqUrl, type = 'POST', data = {}, head) {

  if (!reqUrl) { return }
  
  let headers = head || {}
  type = type.toUpperCase()
  // console.log(window.env)
  reqUrl = window.env.API_HOST + reqUrl
  
  let axiosData
  if (type === 'POST') {
    axiosData = {
      timeout: 8000,
      headers: headers,
      method: type,
      url: reqUrl,
      data: data
    }
  } else {
    if (JSON.stringify(data) !== '{}') {
      reqUrl += '?' + urlencode(data)
    }
    axiosData = {
      timeout: 30000,
      headers: headers,
      method: type,
      url: reqUrl
    }
  }
  // console.log(axiosData)
  return new Promise((resolve, reject) => {
    axios(axiosData).then(res => {
      if (res.data.code === 2000) {
        resolve(res.data)
      } else {
        reject(res.data)
      }
    }, error => {
      reject(error)
    })
  })
}

