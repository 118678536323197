<template>
<div class="app-container">
  <div class="topDIng">
    <div class="zhanwei"></div>
    <div class="NavBoxs">
      <img src="../assets/logos.png" alt="" />
      <div style="display: flex;flex-grow: 1;">
        <vuedraggable @end="onEnd" :distance="1" handle=".mes" v-model="list" animation="1000" class="Nav_right">
          <div class="mes" v-for="item in list" :key="item.type" :style="
                item.type === listQuery.type ? '' : 'color:#666;font-weight:400'
              ">
            <span v-if="$store.state.wind" style="" @touchmove="helmo" @click="selType(item)" @touchend="selType(item)">{{
                item.name
              }}</span>
            <span v-if="!$store.state.wind" style="" @touchmove="helmo" @touchend="selType(item)" @click="selType(item)">{{
                item.name
              }}</span>

            <div :style="item.type === listQuery.type ? '' : 'opacity: 0;'" class="heng"></div>
          </div>
        </vuedraggable>
        <div class="inps">
          <el-input @input="keywordShuRu" suffix-icon="el-icon-search" v-model="listQuery.keyword" @keyup.enter.native="getList" placeholder="请输入内容"></el-input>
        </div>
        <div v-show="!$store.state.tk" @click="denglvs" class="denglu">
          <img src="../assets/denglu.png" />
          <span>登录</span>
        </div>
        <div @click="changeFullScreen" class="quanping">
          <img :src="$store.state.fullscreen ? noQ : Q" alt="" />
        </div>
      </div>
    </div>
  </div>

  <div class="NavBoxIph">
    <img style="width: 170px" src="../assets/logos.png" alt="" />
    <div>
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          产品类目<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu class="droClss" slot="dropdown">
          <el-dropdown-item v-for="item in list" :key="item.type">
            <span @click="selType(item)" :class="item.type === listQuery.type ? 'dropdown-item' : ''">{{ item.name }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
  <div class="hejiBox">
    <div class="box_centen" style="">
    </div>
    <div>
      <div class="box_min_Bin" v-for="item in pacesetterList" :key="item.category_id">
        <div>
          <div></div>
          {{item.category_name}}
        </div>
        <div class="box_min_Bin_Box">
          <div @click="addRoutine(item2)" class="min_nei" v-for="item2 in item.introduction" :key="item2.int_id">
            <div>
              <img :src="item2.image" alt="" />
              <div>
                <div>{{item2.int_name}}</div>
                <div>项目编号：{{item2.serial_number}}</div>
                <div class="wendan_Bin">
                  <div v-for="item3 in item2.fileList" :key='item3.url'>
                    <!-- <img v-if="item3.type == 'mp4'" :src="tubiao.MP4" alt="">
                    <img v-else-if="item3.type == 'pdf'" :src="tubiao.pdf" alt="">
                    <img v-else-if="item3.type == 'docx'" :src="tubiao.DOCX" alt="">
                    <img v-else-if="item3.type == 'pptx'" :src="tubiao.ppt" alt="">
                    <img v-else :src="tubiao.wenj" alt="">
                    <div>{{item3.name}}</div> -->

                    <a class="tagAb" download :href="item3.url" target="downloadFile">
                      <div>
                        <img v-if="item3.type == 'mp4'" :src="tubiao.MP4" alt="">
                        <img v-else-if="item3.type == 'pdf'" :src="tubiao.pdf" alt="">
                        <img v-else-if="item3.type == 'docx'" :src="tubiao.DOCX" alt="">
                        <img v-else-if="item3.type == 'pptx'" :src="tubiao.ppt" alt="">
                        <img v-else :src="tubiao.wenj" alt="">
                        <span>{{item3.name}}</span>
                      </div>
                    </a>

                  </div>
                </div>
              </div>
            </div>
            <div v-html="item2.content"></div>
          </div>
        </div>
      </div>
      <div style="height:20px"></div>
    </div>
  </div>
</div>
</template>

<script>
import {
  getInfo,
  editorder,
  introduction,
  sortProduct
} from "../api/phonepage";
import vuedraggable from "vuedraggable";
export default {
  name: "Homes",
  data() {
    return {
      newRoutine: false,
      total: 0,
      timeout: null,
      timeoutBottom: null,
      userInfo: null,
      tuozhuai: false,
      listQuery: {
        type: 1,
        keyword: "",
        page: 1,
        limit: 16,
      },
      pacesetterList: [],
      list: [],
      fullscreen: false,
      noQ: require("../icons/svg/noQuanping.svg"),
      Q: require("../icons/svg/Quanping.svg"),
      tubiao: {
        DOCX: require('../SVG/DOCX.svg'),
        MP4: require('../SVG/MP4.svg'),
        pdf: require('../SVG/pdf.svg'),
        wenj: require('../SVG/wenj.svg'),
        ppt: require('../SVG/ppt.svg'),
        xianqin: require('../SVG/xiangqing.svg')
      },
    };
  },
  computed: {},
  components: {
    vuedraggable,
  },
  created() {

    this.sortProduct()
  },
  mounted: function () {
    this.pacesetterList = []
    // window.onscroll = () => {
    //   if (
    //     this.getScrollHeightW() - 20 <=
    //     this.getWindowHeight() + this.getDocumentTop()
    //   ) {
    //     if (this.timeoutBottom) {
    //       clearTimeout(this.timeoutBottom);
    //     }
    //     this.timeoutBottom = setTimeout(() => {
    //       console.log("bottom");
    //       this.listQuery.page = this.listQuery.page + 1;
    //       this.getList();
    //     }, 200);
    //   }
    // };

  },

  deactivated() {
    console.log('add')
  },
  methods: {
    sortProduct() {
      sortProduct().then(r => {
        console.log(r)
        this.list = r.data
        this.getInfo();
      })
    },
    getInfo() {
      if (this.$store.state.tk) {
        console.log(this.$store.state.tk);
        let head = {
          "x-token-u": this.$store.state.tk,
        };
        getInfo({}, head).then((r) => {
          this.userInfo = r.data;
          let Shunxi = this.userInfo.order.split(",");
          let ShunxiArr = [];
          Shunxi.forEach((e) => {
            this.list.forEach((w) => {
              e == w.type ? ShunxiArr.push(w) : ''
            })
          });
          this.list = ShunxiArr;
          let lq = this.listQuery
          lq.type = this.list[0].type
          this.getList()
        });
      } else {
        let lq = this.listQuery
        lq.type = this.list[0].type
        this.getList();
      }

    },
    getList() {
      // this.listLoading = false
      // debugger
      console.log(this.listQuery)
      // let data = this.listQuery.keyword ? JSON.parse(JSON.stringify(this.listQuery)) : this.listQuery
      // data.keyword ? data.type = '' : ''
      introduction(this.listQuery).then((response) => {
        this.pacesetterList = response.data
        this.total = response.data.total;
        console.log(this.pacesetterList)
      });
    },
    Take(res) {
      if (res.length % 2 !== 0) {
        res.push("k");
        this.Take(res);
      } else {
        this.pacesetterList = this.pacesetterList.concat(res);
        console.log(this.pacesetterList);
        return res;
      }
      // console.log()
    },

    addRoutine(item) {
      // console.log(this.list)
      // return
      if (item == "k") return;
      this.$router.push({
        name: `transfer`,
        query: item,
      });
    },
    yvlan(item) {
      window.open(item.url, '_blank')
    },
    selType(item) {
      // return
      if (this.tuozhuai) {
        this.tuozhuai = false;
        return;
      }
      console.log(item)
      this.pacesetterList = [];
      this.listQuery.type = item.type;
      this.listQuery.page = 1;
      // alert('11')
      this.getList();
    },
    helmo(e) {
      // console.log(e)
      this.tuozhuai = true;
      // if(tuozhuai)
    },

    onEnd() {
      if (!this.$store.state.tk) return;
      console.log("销毁onEnd");
      let head = {
        "x-token-u": this.$store.state.tk,
      };
      let ash = "";
      this.list.forEach((e) => {
        ash = ash + e.type + ",";
      });
      ash = ash.substr(0, ash.length - 1);
      console.log(ash);
      console.log(this.userInfo);
      editorder({
          user_id: this.userInfo.user_id,
          order: ash,
        },
        head
      ).then((d) => {
        console.log(d);
      });
    },
    keywordShuRu() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.pacesetterList = [];
        this.listQuery.page = 1;
        this.getList();
      }, 800);
    },

    changeFullScreen() {
      const element = document.documentElement;
      // 如果是全屏状态
      console.log(this.fullscreen);
      if (this.$store.state.fullscreen) {
        // 如果浏览器有这个Function
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        // 如果浏览器有这个Function
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
      }
      // 判断全屏状态的变量
      this.$store.commit("saveFullscreen", !this.$store.state.fullscreen);
      this.fullscreen = !this.fullscreen;
    },

    getDocumentTop() {
      var scrollTop = 0,
        bodyScrollTop = 0,
        documentScrollTop = 0;
      if (document.body) {
        bodyScrollTop = document.body.scrollTop;
      }
      if (document.documentElement) {
        documentScrollTop = document.documentElement.scrollTop;
      }
      scrollTop =
        bodyScrollTop - documentScrollTop > 0 ?
        bodyScrollTop :
        documentScrollTop;
      return scrollTop;
    },

    getWindowHeight() {
      var windowHeight = 0;
      if (document.compatMode == "CSS1Compat") {
        windowHeight = document.documentElement.clientHeight;
      } else {
        windowHeight = document.body.clientHeight;
      }
      return windowHeight;
    },

    getScrollHeightW() {
      var scrollHeight = 0,
        bodyScrollHeight = 0,
        documentScrollHeight = 0;
      if (document.body) {
        bodyScrollHeight = document.body.scrollHeight;
      }
      if (document.documentElement) {
        documentScrollHeight = document.documentElement.scrollHeight;
      }
      scrollHeight =
        bodyScrollHeight - documentScrollHeight > 0 ?
        bodyScrollHeight :
        documentScrollHeight;
      return scrollHeight;
    },
    denglvs() {
      window.location.href = '/admin/#/'
      // window.location.href = '/#/?tk='+hasToken
    },

  },

  destroyed() {
    window.onscroll = null
    console.log('销毁前')
  }
};
</script>

<style scoped>
@media only screen and (max-width: 650px) {
  .box_centen>div {
    width: 100% !important;
    height: auto;
    margin: 20px auto !important;
  }

  .NavBoxs {
    display: none !important;
  }

  .zhanwei {
    display: none !important;
  }

  .NavBoxIph {
    /* display: block !important; */
    height: 200px;
    /* border: 1px solid #000; */
    padding: 0 48px;
    box-sizing: border-box;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }

  .NavBoxIph>img {}

  .droClss {}

  .droClss /deep/ .el-dropdown-menu__item {
    padding: 50px 30px;
    text-align: center;
  }
}

.topDIng {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.dropdown-item {
  color: rgb(71, 208, 226);
}

body {
  background: #f7f7f7;
}

.Home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  box-sizing: border-box;
  /* background-color: #fff; */
}

.zhanwei {
  width: 100%;
  height: 44px;
  background: #fff;
}

.NavBoxs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 48px;
  box-sizing: border-box;
  background: #fff;
}

.NavBoxs>img:nth-of-type(1) {
  width: 285px;
  height: 32px;
}

.Nav_right {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  padding-left: 108px;
  box-sizing: border-box;
}

.Nav_right>div {
  font-size: 30px;
  font-weight: 600;
  /* width: 250px;
  border: 1px solid #FFF; */
  /* margin-left: 70px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heng {
  width: 100%;
  height: 6px;
  background: #f8b900;
  margin-top: 23px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* display: block; */
}

.hejiBox {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.hejiBox>div:nth-of-type(2) {
  flex-grow: 1;
  background-color: #F8F8F8;
}

.box_centen {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 48px;
  box-sizing: border-box;
  height: 120px;
  background-color: #F8F8F8;
}

.box_centen>div {
  width: 24%;
  margin-top: 34px;
  height: auto;
}

.capFont {
  padding: 14px;
  font-size: 28px;
  text-align: center;
  margin-top: 18px;
}

.imgBoxONes {
  /* height: 280px; */
}

.NavBoxIph {
  display: none;
}

.quanping>img {
  width: 40px;
}

.denglu {
  display: flex;
  /* align-items: center; */
  margin: 0 25px;
}

.denglu>img {
  width: 40px;
  height: 40px;
}

.denglu>span {
  font-size: 26px;
  font-weight: 400;
  color: #333333;
  display: flex;
  margin-left: 5px;
}

.inps {
  width: 400px;
  margin-left: 108px;
  /* border: 1px solid #000; */
  box-sizing: border-box;
  height: auto;
}

.inps /deep/ .el-input__inner {
  font-size: 20px;
}

/* 3.26版 */
.box_min_Bin {}

.box_min_Bin>div:nth-of-type(1) {
  width: 100%;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  color: #000000;
  display: flex;
  align-items: center;
  padding: 30px 40px;
  padding-bottom: 0px;
  box-sizing: border-box;
}

.box_min_Bin>div:nth-of-type(1)>div {
  width: 8px;
  height: 34px;
  background: #f8b900;
  border-radius: 4px;
  margin-right: 12px;
}

.box_min_Bin_Box {
  display: grid;
  grid-template-columns: repeat(2, 48.9%);
  width: 100%;
  padding: 0 40px;
  box-sizing: border-box;
  justify-content: space-between;
}

.min_nei {
  height: 449px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 30px;
  box-sizing: border-box;
  margin-top: 30px;
}

.min_nei>div:nth-of-type(1) {
  width: 100%;
  height: 255px;
  display: flex;
}

.min_nei>div:nth-of-type(1)>img {
  width: 42.5%;
  height: 100%;
  object-fit: cover;
}

.min_nei>div:nth-of-type(1)>div {
  flex-grow: 1;
  height: 100%;
  padding-left: 40px;
  box-sizing: border-box;
}

.min_nei>div:nth-of-type(1)>div>div:nth-of-type(1) {
  font-size: 32px;
  font-weight: 700;
  color: #222222;
}

.min_nei>div:nth-of-type(1)>div>div:nth-of-type(2) {
  font-size: 24px;
  font-weight: 400;
  color: #666666;
  margin-top: 9px;
}

.wendan_Bin {
  width: 100%;
  height: 120px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 38px;
  overflow: auto;
}

.wendan_Bin>div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 16px;
}

.wendan_Bin>div>img {
  width: 66px;
}

.wendan_Bin>div>div {
  font-size: 18px;
  font-weight: 400;
  width: 5em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 12px;
  text-align: center;
}

.min_nei>div:nth-of-type(2) {
  max-height: 110px;
  line-height: 30px;
  font-size: 24px;
  font-weight: 400;
  color: #333333;
  overflow: auto;

  /* border: 1px solid #000; */
}

.tagAb {
  text-decoration: none;
}

.tagAb>div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 11px;
}

.tagAb>div>img {
  width: 66px;
}

.tagAb>div>span {
  width: 5em;
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
  color: #333333;
  text-align: center;
}
</style>
