import {
  requestApi
} from '../util/network'

export const Storeitem =
  () =>
    requestApi(
      '/api/weather/last',
      'POST', {}
    )
export const irrigation =
  () =>
    requestApi(
      '/api/Grow/info',
      'POST', {}
    )
export const fires =
  () =>
    requestApi(
      '/api/FireAlarm/info',
      'POST', {}
    )
export const introduction =
  (query) =>
    requestApi(
      'introduction/getlist',
      'POST', query
    )
export const detailsIntroduction =
  (query) =>
    requestApi(
      '/introduction/details',
      'GET', query
    )
export const getInfo =
  (query, head) =>
    requestApi(
      '/user/info',
      'GET', query, head
    )
export const editorder =
  (query, head) =>
    requestApi(
      '/user/editorder',
      'GET', query, head
    )
export const sortProduct =
  (query) =>
    requestApi(
      'category/sortProduct',
      'GET', query
    )
    // category/sortProduct?count=4



    // export function detailsIntroduction(query) {
//   return request({
//     url: '/introduction/details',
//     method: 'get',
//     params: query
//   })
// }


    // export const introduction(query) {
//   return request({
//     url: '/introduction/index',
//     method: 'get',
//     params: query
//   })
// }