<template>
<div id="app">
  <keep-alive :include="['Homes']">
    <router-view />
  </keep-alive>
</div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  mounted: function () {

  },
  methods: {

  },
};
</script>

<style>
body {
  margin: 0;
}

.app-container {}

.filter-container {
  padding-bottom: 10px;
}

.filter-item {
  display: inline-block;
  vertical-align: middle;
}

.tagA {
  /* text-decoration: underline; */
  text-decoration: none;
}

.tagA>div {
  display: flex;

}

.tagA>div>img {

  width: 36px;
  margin-right: 12px;
}

.tagA>div>span {
  width: 14em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
  color: #333333;
}
</style>
