import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tk: '',
    fullscreen:false,
    wind:false
  },
  mutations: {
    saveTk(state, res) {
      // console.log(res)
      state.tk = res
    },
    saveFullscreen(state, res) {
      // console.log(res)
      state.fullscreen = res
    },
    saveWind(state, res) {
      // console.log(res)
      state.wind = res
    },
  },
  actions: {

  },
  modules: {
  }
})